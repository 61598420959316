import { useState } from "react"
import { Link, Navigate } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import upload from "../images/upload.png"


export default function Register() {
  const [filepath,setFilepath] = useState()
  const [error,setError] = useState({
    displayName:"",
    email:"",
    password:"",
    cpassword:""
  })
  const [thumbnail, setThumbnail] = useState()
  const [thumbnailError, setThumbnailError] = useState()
  const {signup, isPending, loginError,  success} = useAuthContext()

    const handleFileChange = (e)=>{
        setThumbnail(null)
        let selected = e.target.files[0]
       if(selected){
              if(!selected.type.includes('image')){
                setThumbnailError('selected file must be an image')
                return
              }
              if(selected.size > 2000000){
                setThumbnailError('file size is more than allowed')
                return
              }
    
              setThumbnailError(null)
              setThumbnail(selected)
              setFilepath(URL.createObjectURL(selected))
       
        
          }
    }

   const handleSubmit = (e)=>{
    e.preventDefault()
    setError({displayName:"",email:"",password:"",cpassword:""})
   const displayName = e.target[0].value
   const email = e.target[1].value
   const password = e.target[2].value
   const cpassword = e.target[3].value
   const plen = e.target[2].value.length
    if(!displayName){
      setError({...error, displayName:"name is to be entered"})
      return
      }
     else if(!email){
        setError({...error, email:"email is to be entered"})
        return
     }
     else if(!password){
        setError({...error, password:"password is to be entered"})
        return
     }
  
     else if(cpassword !== password){
        setError({...error, cpassword:"confirm password shoul match password "})
        return
     }
     else{
       signup(displayName,email,password,thumbnail)
     }
}


if(!success){

  return (
        <div className=" min-h-screen flex items-center justify-center bg-themel3">
           <div className="w-full md:w-3/12 bg-themed1 rounded-lg flex flex-col items-center space-y-3 px-4 py-2">
               <p className="bg-themed4 px-4 py-2 text-xl font-bold text-themel3 rounded-lg ">REGISTER</p>  
               <form onSubmit={handleSubmit} className="w-full flex flex-col items-center space-y-2">
                   <input
                      type="text"
                      placeholder="enter your name"
                      className="w-full ring-1 rounded-lg px-3 py-2 bg-themel4"
                    />
                    {error && <p className="text-sm font-bold text-red-600">{error.displayName}</p>}
                     <input
                      type="email"
                      placeholder="enter your email"
                      className="w-full ring-1 rounded-lg px-3 py-2 bg-themel4"
                     
                     
                    />
                    {error && <p className="text-sm font-bold text-red-600">{error.email}</p>}
                     <input
                      type="password"
                      placeholder="enter your password"
                      className="w-full ring-1 rounded-lg px-3 py-2 bg-themel4"
                    
                     
                    />
                  {error && <p className="text-sm font-bold text-red-600">{error.password}</p>}
                      <input
                      type="password"
                      placeholder="re-enter your password"
                      className="w-full ring-1 rounded-lg px-3 py-2 bg-themel4"
                    
                     
                    />
                   {error && <p className="text-sm font-bold text-red-600">{error.cpassword}</p>}
                    <input
                       type="file"
                       id="file"
                       className="hidden w-full ring-1 rounded-lg px-3 py-2 bg-themel4"
                       onChange={handleFileChange}
                     />
                     <label htmlFor="file" className="flex items-center gap-2">
                      {filepath && <img src={filepath} className=" w-16 h-16 rounded-full object-cover cursor-pointer" /> }
                          
                      
                      {!filepath &&  <img src={upload} className=" w-16 h-16 object-cover cursor-pointer" />}
                       
                        <p className="text-white font-bold text-xs cursor-pointer">Select your profile photo</p>
                     </label>
                     {thumbnailError && <p className="text-sm font-bold text-red-600">{thumbnailError}</p>}
                    {!isPending && <button className="px-3 py-2 rounded-lg bg-themed3 text-themel3 text-sm font-bold">Submit</button>}
                    {isPending && <button className="px-3 py-2 rounded-lg bg-themed3 text-themel3 text-sm font-bold">Signing you up .....</button>}
                    {loginError && <p className="text-sm font-bold text-red-600">{loginError}</p>}
                   
               </form> 
               <Link to="/login"><p className="text-xs font-bold text-themed4">If you have account already kindly <span className="font-bold text-white text-sm">Login</span></p></Link>
           </div>
      </div>
   
  )
 }else{
    return (<Navigate  to="/" />)
 }
}
