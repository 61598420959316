import Search from "../components/Search"
import {doc, onSnapshot, getDocs, collection,query, where} from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../firebase"
import { useRouteError } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import { useChatContext } from "../hooks/useChatContext"
import head from "../images/csim1.png"
import flower from "../images/flower.png"
import dummy from "../images/dummy.webp"

export default function Chats() {
  const {currentUser} = useAuthContext()
  const [logged,setLogged] = useState(null)
  const {dispatch} = useChatContext()

  useEffect(()=>{
   const colRef = collection(db,"logged")
   
    const unsub = onSnapshot(colRef,(snapshot)=>{
      let usersonline = []
        snapshot.docs.forEach((doc)=>{
          usersonline.push(doc.data())
       
         
        })
       let online = usersonline.filter(user=>user['uid'] !== currentUser.uid)
        setLogged(online)
    })
   
  
    
    return ()=>{
      unsub()
    }
  },[])

  const handleSelect=(chatuser)=>{
     dispatch({type:"CHAT_SELECT",payload:chatuser})
  }

  const group = {
    uid:"groupabcd1234567",
    type:"group",
    displayName:"group1",
    photoURL: ""
  }

  const group1 = {
    uid:"familyabcd1234567jag",
    type:"group",
    displayName:"Our Family",
    photoURL: flower
  }




  
  
  return (

    <div className=' h-96 bg-themed1 py-5 px-2'>
        {logged && logged.map((item)=>(
         <div onClick={()=>handleSelect(item)} key={item.uid} className="w-full flex flex-row items-center hover:bg-slate-400 justify-start space-x-2 space-y-3">
           
              {item.photoURL && <img src={item.photoURL} alt={item.displayName} className="block w-7 h-7 rounded-full mb-4 " />}
              {!item.photoURL && <img src={dummy} alt={item.displayName} className="block w-7 h-7 rounded-full mb-4 " />}
              <p className="text-xs font-bold text-white">{item.displayName}</p>
           
         </div> 
         
        ))}   
       <div onClick={()=>handleSelect(group)} className="w-full mt-3 flex flex-row items-center hover:bg-slate-400 justify-start space-x-2 space-y-3">
           
           <img src={head} alt="groupchat" className="block w-4 h-4 rounded-full mb-4 " />
           <p className="text-xs font-bold text-white">Group Chat</p>
        
        </div> 

        <div onClick={()=>handleSelect(group1)} className="w-full mt-3 flex flex-row items-center hover:bg-slate-400 justify-start space-x-2 space-y-3">
           
           <img src={flower} alt="groupchat" className="block w-4 h-4 rounded-full mb-4 " />
           <p className="text-xs font-bold text-white">Our Family</p>
        
        </div> 
       
    </div>
    
    
  )
}
