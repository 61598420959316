

export default function InfoPanel() {
  return (
    <div className="h-96 bg-themel2 flex items-center justify-center p-3 ">
        <p className=" text-2xl text-themed3 font-extrabold opacity-30">SELECT A NAME OR GROUP FROM  PANEL LEFT/ABOVE</p>
       
    </div>
  )
}
