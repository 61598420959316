// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth"
import {getStorage} from "firebase/storage"
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAmG1JDYvQhssRMsz3RlRd2Xb-d9bzOeJE",
  authDomain: "chat-e6a14.firebaseapp.com",
  projectId: "chat-e6a14",
  storageBucket: "chat-e6a14.appspot.com",
  messagingSenderId: "803521022503",
  appId: "1:803521022503:web:189485964e5bbaf5d31c02"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const storage = getStorage()
export const db = getFirestore()

