import { useState } from "react"
import { useAuthContext } from "../hooks/useAuthContext"
import upload from "../images/upload.png"
import { uploadBytesResumable, getDownloadURL, ref} from "firebase/storage"
import { doc, setDoc, getDoc, Timestamp } from "firebase/firestore";
import { db, storage } from "../firebase";
import { v4 as uuid } from "uuid";
import logo from "../images/logo192.png"


export default function Input({combinedId}) {
  const[filepath, setFilepath] = useState()
  const [thumbnailError,setThumbnailError] = useState()
  const [thumbnail,setThumbnail] = useState()
  const {currentUser} = useAuthContext()
  const [isPending,setIsPending] = useState(false)
  const [imgurl,setImgurl] = useState('')
  const [text,setText] = useState()
  const [error,setError] = useState()



  const handleFileChange = async (e)=>{
     let selected = e.target.files[0]

     if(selected){

      if(!selected.type.includes('image')){
        setThumbnailError('selected file must be an image')
        return
      }
      if(selected.size > 2000000){
        setThumbnailError('file size is more than allowed')
        return
      }

      setThumbnailError(null)
      setThumbnail(selected)
      setFilepath(URL.createObjectURL(selected))


     }
    }
     
     const handleSubmit = async (e)=>{
      e.preventDefault()
      setError('')

       if(!text){
        setIsPending(true)
        
          setError("say something before sending")
          setIsPending(false)
          return
          
       }
       setIsPending(true)
        const  storageRef = ref(storage, `${currentUser.displayName}` )

        if(thumbnail){
          await uploadBytesResumable(storageRef, thumbnail).then(()=>{
                 getDownloadURL(storageRef).then((downloadURL)=>{
                   setDoc(doc(db,"userChats", uuid()),{
                    id:combinedId,
                     sender:{
                       id:currentUser.uid,
                       displayName:currentUser.displayName,
                       photoURL:currentUser.photoURL
                     },
                   
                     text,
                     photo:downloadURL,
                     date:Date.now()
                   }).then(()=>{
                     setText('')
                     setFilepath('')
                     setIsPending(false)
                     setThumbnail(null)
                   })
                  
                 })
               })
             }else{
               setDoc(doc(db,"userChats", uuid()),{
                 id:combinedId,
                 sender:{
                   id:currentUser.uid,
                   displayName:currentUser.displayName,
                   photoURL:currentUser.photoURL
                 },
                
                 text,
                 photo:null,
                 date:Date.now()
               }).then(()=>{
                 setText('')
                 setFilepath('')
                 setIsPending(false)
                 setThumbnail(null)
               })
             }
       
     
  }
      
     
      
     


  


  return (
    <div className='bg-themel3 p-2'>
       {error && <p className="text-xs font-bold text-red-500 ">{error}</p>}
        <form onSubmit={handleSubmit}   className="flex items-center justify-between">
         
           <textarea
             placeholder='type something'
             type="text"
             className="w-5/6 mr-2 rounded-md bg-transparent text-themed2 border border-themel2"
             onChange={(e)=>setText(e.target.value)}
             value={text}
             >
             
           </textarea>
          
      
           <input 
              type="file"
              id="file"
              className="hidden "
              onChange={handleFileChange}
           />
           <div className="flex items-center justify-center gap-2">
                <label htmlFor="file" className="flex items-center gap-2">
                    {filepath && <img src={filepath} className=" w-16 h-16 rounded-full object-cover cursor-pointer" /> }
                        
                    
                    {!filepath &&  <img src={upload} className=" w-6 h-6 object-cover cursor-pointer" />}
                     
                      <p className="text-white font-bold text-xs cursor-pointer"></p>
                   </label>

                   {thumbnailError && <p className="text-red-500 text-sm font-bold">{thumbnailError}</p>}

                  {!isPending && <button className="px-3 py-1 bg-themed2 rounded-lg text-themel4">Send</button>}
                  {isPending && <button className="px-3 py-1 bg-themed2 rounded-lg text-themel4"><img src={logo} className="w-3 h-3 animate-spin" /></button>}
              </div>
        </form>
    </div>
  )
}
