import React, { useEffect, useRef } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import moment from 'moment'

export default function Message({chatmsg}) {

  const {currentUser} = useAuthContext()
 
 
  const owner = currentUser.uid == chatmsg.sender.id? true : false

  const ref = useRef()

  useEffect(()=>{
    ref.current?.scrollIntoView({behaviour:"smooth"})
  },[])

  


 return (
  <div  className='flex items-end space-x-2 mx-4'  style={{"flex-direction": owner?"row-reverse":"row"}}>  
   {chatmsg && (
    <div ref={ref} className=' max-w-xs flex items-center justify-center'>
       <div className='flex flex-col items-center space-x-2'>
           <img src={chatmsg.sender.photoURL} alt="" className='w-4 h-4 rounded-full ' />
          
       </div>
       <div className='flex flex-col items-center space-y-1'>
            {!owner && <p className='text-xs font-thin text-gray-500'>{chatmsg.sender.displayName}</p>}
           <p className='p-2 text-xs text-themed2 font-bold rounded-tr-md rounded-br-md rounded-bl-md' style={{"backgroundColor":owner? "#91F2EF" : "#EDF2F2"}}>{chatmsg.text}</p>
           {chatmsg.photo && <img src={chatmsg.photo}  alt=""  className=' w-24 h-28' />}
           <i className=' text-xs text-gray-400 font-medium'>{moment(chatmsg.date).fromNow()}</i>
       </div>

    </div>
  )}
    </div> 
  )
}
