import React from 'react'
import Navbar from './Navbar'
import Chats from './Chats'
import Search from '../components/Search'
import GroupChat from './GroupChat'

export default function Sidebar() {
  return (
    <div className='w-full md:w-1/3 border border-themed3'>
        <Navbar />
        <div className='w-full text-center'>
        <p className='text-sm font-bold text-themed2'>Users online now</p>
        </div>
        <Chats />
       
    </div>
  )
}
