import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import {GoogleButton} from "react-google-button"


export default function Login() {
   const [error,setError] = useState({
    email:"",
    password:""
   })
   const {login,isPending,loginError, signupWithGoogle} = useAuthContext()

    const handleSubmit = (e)=>{
       e.preventDefault()
       setError({email:"", password:""})
       const email = e.target[0].value  
       const password = e.target[1].value 

       if(!email){
          setError({...error, email:"email is to be entered"})
          return
       }
       else if(!password){
           setError({...error, password:"password is to be entered"})
           return
       }
       else{
          login(email,password)
       }
    }

    const handleClick = async (e)=>{
      e.preventDefault()

        signupWithGoogle()

    }

    return (
      <div className=" min-h-screen flex items-center justify-center bg-themel3">
           <div className="w-full md:w-3/12 bg-themed1 rounded-lg flex flex-col items-center space-y-3 px-4 py-2">
               <p className=" w-full bg-themed4 px-4 py-2 text-center text-xl font-bold text-themel3 rounded-lg ">LOGIN</p>  
               <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-2 w-full">
                  
                     <input
                      type="email"
                      placeholder="enter your email"
                      className="w-full ring-1 rounded-lg px-3 py-2 bg-themel4"
                      
                    />
                     <input
                      type="password"
                      placeholder="enter your password"
                      className="w-full ring-1 rounded-lg px-3 py-2 bg-themel4"
                      />
                  
                    {!isPending && <button className="px-3 py-2 rounded-lg bg-themed3 text-themel3 text-sm font-bold">Login</button>}
                    {isPending && <button className="px-3 py-2 rounded-lg bg-themed3 text-themel3 text-sm font-bold">Logging you in ...</button>}
                    {loginError && <p className="text-xs font-bold text-red-700">{loginError}</p>}
               </form> 
               <Link to="/register"><p className="text-xs font-bold text-themed4">If you don't have account kindly <span className="font-bold text-white text-sm">Register</span></p></Link>
               <GoogleButton onClick={handleClick} />
           </div>
      </div>
    )
  }
  