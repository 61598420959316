import Message from "./Message";
import {doc, onSnapshot, getDocs, collection,query, where, orderBy} from "firebase/firestore"
import { useEffect, useRef, useState } from "react";
import { db } from "../firebase";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Messages({combinedId}) {
     const [chatmsg,setChatmsg] = useState()
    const {currentUser} = useAuthContext()
  

  useEffect(()=>{
    const colRef = query(collection(db,"userChats"),where("id","==", combinedId))
    
     const unsub = onSnapshot(colRef,(snapshot)=>{
          let msg = []
         snapshot.docs.forEach((doc)=>{
           msg.push(doc.data())
          
          
         })
         setChatmsg(msg)
     })
    
  
     return ()=>{
       unsub()
     }
   },[combinedId])

   console.log(combinedId)

  const ref = useRef()


  return (
    <div className=" h-96 overflow-y-scroll mx-3 flex flex-col space-y-3">
        {chatmsg && (
         Object.values(chatmsg)?.sort((a,b)=>a.date - b.date)?.map((item)=>(
            <Message chatmsg={item}/>
         ))
          
      )}
      
    </div>
  )
}
