
import elipsis from "../images/ellipsis.png"
import Messages from "./Messages"
import Input from "./Input"
import { useChatContext } from "../hooks/useChatContext"

import InfoPanel from "./InfoPanel"
import { useAuthContext } from "../hooks/useAuthContext"
import { useState } from "react"



export default function Chat() {
  const {chatUser} = useChatContext()
  const {currentUser} = useAuthContext()
  


  const combinedId = chatUser?chatUser.type !=="group" ? currentUser.uid > chatUser.uid ? currentUser.uid + chatUser.uid :chatUser.uid + currentUser.uid:chatUser.uid:""
  

console.log(combinedId)

  return (
    <div className=' w-full md:w-2/3 border bg-themel4 border-themed2'>
      {chatUser && (
         <div className='flex items-center justify-between bg-themed2 p-3'>
         <p className='text-sm font-bold text-themel4'>{chatUser.displayName} {chatUser.last_name}</p>
          <img src={chatUser.photoURL}  className=" w-8 text-white"/>
     </div>
      )}
     
        
    {chatUser && (
      <>
           <Messages combinedId={combinedId}/>
          <Input  combinedId={combinedId} />
      </>
    )}

    {!chatUser && <InfoPanel />}
    
         
         
     
      
    </div>
  )
}
