import { createContext, useReducer } from "react";

export const ChatContext = createContext()
export const chatReducer = (state,action)=>{
   switch(action.type){
      case "CHAT_SELECT":
        return {...state, chatUser:action.payload}
     
      default:
        return {...state}  
   }
}
export const ChatContextProvider = ({children}) =>{
    const [state,dispatch] = useReducer(chatReducer,{
        chatUser:null,
      
    })



    return (
        <ChatContext.Provider  value={{
           ...state,
           dispatch
        }}>
            {children}
        </ChatContext.Provider>
        )
}

