import Sidebar from "../components/Sidebar";
import Chat from "../components/Chat"


export default function Home() {

    
  return (
    <div className=" min-h-screen flex items-center justify-center bg-themel3 p-0 md:p-20 ">
        <div className="w-full md:w-8/12 border border-themed2 md:flex rounded-xl overflow-hidden shadow-xl">
            <Sidebar />
            <Chat />
        </div>  
    </div>
  )
}
