import { useContext } from "react";
import { ChatContext } from "../context/ChatContext";


export const useChatContext = ()=>{
    const context = useContext(ChatContext)
    if (!context){
        throw Error('useAutheCOtext must be inside an AutheContextProvider')
       }
     return context  
}