

import { createContext, useEffect, useReducer, useState } from "react";
import { Navigate} from "react-router-dom";


import { createUserWithEmailAndPassword,
     onAuthStateChanged, 
     signOut,
     updateProfile, 
     signInWithEmailAndPassword,
     signInWithPopup,
     GoogleAuthProvider,
     signInWithRedirect
     } from "firebase/auth";
import { doc, setDoc, getDoc, deleteDoc  } from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL, ref} from "firebase/storage"
import { auth, storage, db } from "../firebase";




export const AuthContext = createContext()
export const authReducer = (state,action)=>{
    switch (action.type) {
        case "SIGNUP":
            return {...state, currentUser:action.payload}
        case "LOGIN":
            return {...state, currentUser:action.payload}
        case "LOGOUT":
                return {...state, currentUser:action.payload}    
        case "ONCHANGE":
            return {...state, currentUser:action.payload}        
        default:
            return {...state}
    }
}

const initialState = {
    currentUser:null,
    isAuthReady:false
}




export const AuthContextProvider =({children})=>{
      const [isPending,setIspending] = useState(false)
      const [loginError, setLoginError] = useState()
      const [success,setSuccess] = useState(false)
      const [state,dispatch] = useReducer(authReducer, {
        currentUser:null,
        isAuthReady:false
      })
      
      useEffect(() => {
        const unsub = onAuthStateChanged(auth, (user) => {
            if(user){
                getDoc(doc(db,"users",user.uid)).then((doc)=>{
                    dispatch({type:"ONCHANGE",payload:{
                        uid:user.uid,
                        type:"single",
                        displayName:doc.data().displayName,
                        email:doc.data().email,
                        photoURL:doc.data().photoURL
    
                    }})
                })
            }
          
         
        });
    
        return () => {
          unsub();
        };
      }, []);

      const dellogged = ()=>{
         
      }


    const signup = async (displayName,email,password,thumbnail)=>{
        setIspending(true)
        try{
       
    const res = await createUserWithEmailAndPassword(auth, email,password)
   
         //Create a unique image name
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`)
        
      await uploadBytesResumable(storageRef, thumbnail).then(()=>{
          getDownloadURL(storageRef).then(async (downloadUrl)=>{
          
            updateProfile(auth.currentUser,{
                displayName,
                photoURL:downloadUrl
             })
           
            setDoc(doc(db,"users",res.user.uid),{
                displayName,
                email,
                photoURL:downloadUrl
               })    

         
              dispatch({type:"SIGNUP", payload:{
                uid:res.user.uid,
                displayName,
                email,
                photoURL:downloadUrl
              }})

             setDoc(doc(db,"logged",res.user.uid),{
                uid:res.user.uid,
                type:"single",
                displayName,
                photoURL:downloadUrl
               
            })
               
              setSuccess(true)
          })
        
     
      })
      setIspending(false)
      
       }catch(error){
         setIspending(true)
         setLoginError(error.message)
         setIspending(false)
       }

    }

    const signupWithGoogle = async ()=>{
         const provider = new GoogleAuthProvider()

         try{
           await signInWithPopup(auth,provider)
           .then((result)=>{

            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            setDoc(doc(db,"users",result.user.uid),{
                displayName:result.user.displayName,
                email:result.user.email,
                photoURL:result.user.photoURL
               })    

         
              dispatch({type:"SIGNUP", payload:{
                uid:result.user.uid,
                displayName:result.user.displayName,
                email:result.user.email,
                photoURL:result.user.photoURL
              }})

             setDoc(doc(db,"logged",result.user.uid),{
                uid:result.user.uid,
                type:"single",
                displayName:result.user.displayName,
                photoURL:result.user.photoURL
               
            })
            setSuccess(true)
               
           })
         }catch(error){
           setLoginError(error.message)
         }
         
    }

     const login = async (email,password)=>{
        try{
            setIspending(true)
           await  signInWithEmailAndPassword(auth,email,password)
           .then((usercred)=>{
           
            const docRef = doc(db,"users", usercred.user.uid)
            
            getDoc(docRef).then((doc)=>{
               dispatch({type:"LOGIN", payload:{
                uid:usercred.user.uid,
                displayName:doc.data().displayName,
                email:doc.data().email,
                photoURL:doc.data().photoURL
               }})

            })
           
            setDoc(doc(db,"logged",usercred.user.uid),{
                uid:usercred.user.uid, 
                type:"single",
                displayName:usercred.user.displayName,
                photoURL:usercred.user.photoURL

            })
          
           })
           setIspending(false)
        }catch(error){
            setIspending(true)
            setLoginError("invalid email or password ")
            setIspending(false)
        }
     }

    const logout = async (id)=>{
     
        setIspending(true)
       
       await deleteDoc(doc(db,"logged",id)).then(()=>{
           signOut(auth).then(()=>{
            dispatch({type:"LOGOUT", payload:null})
         })
       })
        
        setIspending(false) 
    }
     
  

    return (
        <AuthContext.Provider value={{ 
             signup,
             isPending,
             loginError,
             ...state,
             dispatch, 
             logout,
             login,
             success,
             signupWithGoogle
            }}>
          {children}
        </AuthContext.Provider>
      )

}