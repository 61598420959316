import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo192.png"
import {deleteDoc,doc} from "firebase/firestore"
import { db } from "../firebase"
import dummy from "../images/dummy.webp"


export default function Navbar() {

  const {logout, currentUser, isPending} = useAuthContext()
  console.log(currentUser)
  const handleClick = (e)=>{
 
    logout(currentUser.uid)
  }
 

  return (
    <div className='flex items-center justify-between p-2 bg-themed2'>
        <img src={logo} alt="" className=" w-7 h-7 rounded-full" />
        <div className="flex items-ceneter justify-center gap-1">
           {currentUser.photoURL && <img src={currentUser.photoURL} alt={currentUser.displayName} className='w-7 h-7 rounded-full'/>}
           {!currentUser.photoURL && <img src={dummy} alt={currentUser.displayName} className='w-7 h-7 rounded-full'/>}
           <p className="text-sm font-bold text-themel4">{currentUser.displayName}</p>
           <form onSubmit={handleClick}>
              <input type="hidden" value={currentUser.uid} name="logged_id" />
              {!isPending && <p onClick={handleClick} className="bg-themed2 rounded-md cursor-pointer text-sm font-thin  text-themel4">logout</p>}
           {isPending && <p  className="bg-themed2 rounded-md cursor-pointer text-sm font-thin  text-themel4">logging you out ...</p>}
           </form>
           
        </div>
    </div>
  )
}
