import { useEffect } from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import { useAuthContext } from "./hooks/useAuthContext";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";


function App() {

  const {currentUser,dispatch} = useAuthContext()

  return (
    <div className="">
      <BrowserRouter>
       <Routes>
        <Route path="/"
         element={currentUser? <Home />:<Navigate to="/login" />}
          />
         <Route path="/register"
          element={!currentUser?<Register />:<Navigate to="/" />}
          />
         <Route path="/login"
          element={!currentUser ? <Login />:<Navigate to="/" />}
          />
       </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
